import { render, staticRenderFns } from "./AdminChat.vue?vue&type=template&id=030ca7e2&scoped=true"
import script from "./js/admin-chat.js?vue&type=script&lang=js&external"
export * from "./js/admin-chat.js?vue&type=script&lang=js&external"
import style0 from "./AdminChat.vue?vue&type=style&index=0&id=030ca7e2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "030ca7e2",
  null
  
)

export default component.exports