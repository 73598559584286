import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'admin-chat',
  data() {
    return {
      visiblePrompt: sessionStorage.getItem('visiblePrompt') !== 'false'
    }
  },
  components: {
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    Close: () => import(/* webpackChunkName: "icon-close" */ '@/components/icons/Close')
  },
  computed: {
    ...mapGetters('common', ['slug', 'isMobile', 'adminGeneral', 'adminB2C', 'adminCareerPage']),
    ...mapGetters('prakerja', ['myClassDetail', 'nonWebinarClassDetail', 'isContactUsVisible']),
    isProgramDetail() {
      return this.$route.name === 'Program Detail' || this.$route.name === 'Collaboration'
    },
    isPrakerja() {
      return this.$route.name === 'Prakerja'
    },
    isEventDetails() {
      return this.$route.name === 'Event Details'
    },
    isShortCourse() {
      return this.$route.name === 'Short Course'
    }
  },
  methods: {
    ...mapActions('common', ['sendWhatsApp', 'sendWhatsAppPrakerja', 'sendWhatsAppInstallment']),
    ...mapActions('prakerja', ['setContactUsVisible']),
    closePrompt() {
      this.visiblePrompt = false
      sessionStorage.setItem('visiblePrompt', 'false')
    },
    chatWithAdmin: function () {
      // eslint-disable-next-line
      const arr = this.slug.split(/\s*\-\s*/g)
      let topic = 'program%20'
      for (let i = 0; i < arr.length; i++) {
        i === arr.length - 1 ? (topic += `${arr[i]}.`) : (topic += `${arr[i]}%20`)
      }

      if (this.slug === 'prakerja' || this.$route.params.category === 'prakerja' || this.$route.name === 'User Classes') {
        this.$router.push('/help')
        // this.sendWhatsApp({ number: this.adminGeneral, topic })
        // this.setContactUsVisible(!this.isContactUsVisible)
      } else {
        if (this.slug === 'general') {
          switch (this.$route.name) {
            case 'Login':
              if (this.$route.query?.ref === '/prakerja/redeem') {
                window.open(`${window.location.origin}/help`)
              } else {
                this.sendWhatsApp({ number: this.adminGeneral })
              }
              break
            case 'PrakerjaRedeemV3':
              this.$router.push('/help')
              break
            case 'G2 LMS':
            case 'G2 Talent':
            case 'RegisterMitra':
              this.sendWhatsApp({ number: this.adminGeneral, topic: this.$route.name === 'RegisterMitra' ? 'Mitra%20G2Academy.' : `${this.$route.name}.` })
              break
            case 'non-webinar-class':
              this.sendWhatsAppPrakerja({ number: this.nonWebinarClassDetail.customerServiceNumber, mitra: this.nonWebinarClassDetail.customerName, topic: this.nonWebinarClassDetail.coreClassName })
              break
            case 'My Class Detail':
              this.sendWhatsAppPrakerja({ number: this.myClassDetail.customerServiceNumber, mitra: this.myClassDetail.customerName, topic: this.myClassDetail.nama_kelas })
              break
            case 'Career Page':
            case 'Career Detail Page':
              this.sendWhatsApp({ number: this.adminCareerPage, topic: this.$route.params.position ? `posisi%20${this.$route.params.position}` : 'G2%20Career%20Page.' })
              break
            case 'Open Day':
            case 'Scholarship':
            case 'Programs':
              this.sendWhatsApp({ number: this.adminB2C, topic: this.$route.name === 'Programs' ? undefined : `program%20${this.$route.name}.` })
              break
            case 'Education':
              this.sendWhatsApp({ number: this.adminGeneral, topic: this.$route.name === 'G2Lab' ? 'Project' : 'Education Institutes' })
              break
            case 'Corporate Solutions':
            case 'Talent Pipeline':
            case 'G2Lab':
            case 'Government':
            case 'Corporate Training':
              this.sendWhatsApp({ number: this.adminGeneral, topic: this.$route.name })
              break
            case 'Installment':
              this.sendWhatsAppInstallment({ number: this.adminGeneral })
              break
            default:
              this.sendWhatsApp({ number: this.adminGeneral })
              break
          }
        } else {
          this.sendWhatsApp({ number: this.adminB2C, topic })
        }
      }
    }
  }
}
